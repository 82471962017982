import * as React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

import "./HeaderNavbar.css";

const pageInfos = [
  // {
  //   label: "Top",
  //   href: "/",
  // },
  {
    label: "About",
    href: "/About",
  },
  // {
  //   label: "News",
  //   href: "/News",
  // },
  // {
  //   label: "Blog",
  //   href: "/Blog",
  // },
  // {
  //   label: "Link",
  //   href: "/Link",
  // },
  // {
  //   label: "Contact",
  //   href: "/Contact",
  // },
];

const HeaderNavbar = () => {
  return (
    <>
      <Navbar bg="dark" variant="dark" >
        <Container>
          <Navbar.Brand href="/">Shakke.jp</Navbar.Brand>
          <Nav className="me-auto">
            {pageInfos.map((pageInfo) => (
              <Nav.Link href={pageInfo.href}>{pageInfo.label}</Nav.Link>
            ))}
          </Nav>
        </Container>
      </Navbar>

      
    </>
  );
};
export default HeaderNavbar;
