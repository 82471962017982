import React, { useEffect, useState } from "react";

interface TopImageProps {
    style?: React.CSSProperties;
}

const TopImage: React.FC<TopImageProps> = ({style}) => {
    const [randomFile, setRandomFile] = useState('');

    useEffect(() => {
        // ファイル名の配列を取得する処理
        const fileNames = [
            // '/image/webp/topImage_cinema.webp',  
            // '/image/webp/banana-2.webp',  
            '/image/webp/hall-2.webp',  
        ];

        // ランダムなファイル名を選択する処理
        const randomIndex = Math.floor(Math.random() * fileNames.length);
        const randomFileName = fileNames[randomIndex];
        setRandomFile(randomFileName);
    }, []);

    return (
        <img
            src={randomFile}
            width="100%"
            style={style}
        ></img>
    );
}

export default TopImage;
