import { useSpring, animated } from "react-spring";
import React, { useEffect, useState } from "react";

import "./BackgroundImage.css";

const BackgroundImage: React.FC<{isDark:boolean}> = (props) => {
  const isDark = props.isDark;
  const filterSrc = `/image/svg/top_image_filter_${isDark ? "black" : "white"}.svg`;
  const fixedFillterSrc = `/image/svg/top_image_filter_black.svg`;

  const [randomFile, setRandomFile] = useState('');

  useEffect(() => {
    // ファイル名の配列を取得する処理
    const fileNames = [
      // '/image/webp/kinkakuji.webp',  
      // '/image/webp/shojiko.webp',  
      // '/image/gif/square.gif',  
      // '/image/gif/pipe.gif',  
      '/image/webp/gtr.webp',  
      '/image/webp/pipe.webp',  
      '/image/webp/hall.webp',  
    ];

    // ランダムなファイル名を選択する処理
    const randomIndex = Math.floor(Math.random() * fileNames.length);
    const randomFileName = fileNames[randomIndex];
    setRandomFile(randomFileName);
  }, []);

  const imageProps = useSpring({
    opacity: 1,
    delay: 1000,
    from: {
      opacity: 0,
    },
    config: {
      duration: 500
    },
  });

  const fixedFilterProps = useSpring({
    opacity: 1,
  });

  const imageLinkStyles: { [key: string]: React.CSSProperties } = {
    container: {
      backgroundColor: "black",
      position: 'relative',
      display: 'inline-block',
      width: "100%",
      height: "100vh"
    },
    image: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      height: "100vh",
      transform: 'translate(-50%, -50%)',
      objectFit: "cover",
      opacity: imageProps.opacity as any,
    },
    fixedImage: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      height: "100vh",
      transform: 'translate(-50%, -50%)',
      objectFit: "cover",
      opacity: fixedFilterProps.opacity as any,
    }
  };
  
  return (
    <div
        className='background-container'
        style={imageLinkStyles.container}
      >
        <animated.img
          src={randomFile}
          className='background-image'
          style={{ ...imageLinkStyles.image, zIndex: 0 }}
        />
        <animated.img
          src={fixedFillterSrc}
          className='background-image'
          style={{ ...imageLinkStyles.fixedImage, zIndex: 1 }}
        />
        <animated.img
          src={filterSrc}
          className='background-image'
          style={{ ...imageLinkStyles.image, zIndex: 2 }}
        />
      </div>
  );
}

export default BackgroundImage;
