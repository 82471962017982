import * as React from "react";
import './Copyright.css';

const mailAddress = 'yuuki.yamada9521@gmail.com'

function Footer() {
    return (
      <p className="copyright">
        {'Copyright © '}
        <a color="inherit" style={{fontSize:"inherit"}} href={`/Contact`}>
          Yuki Yamada
        </a>
        {' '}
        {new Date().getFullYear()}
        {'.'}
      </p>
    );
  }

export default Footer;