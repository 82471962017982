// App.tsx
import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import TopPage from "./pages/top-page/TopPage";
import NotFound from "./pages/not-found/NotFound";
import StayTune from "./pages/stay-tune/StauTune";
import About from "./pages/about/About";
import SandBox from "./pages/sand-box/SandBox";
import Contact from "./pages/contact/Contact";

function App() {
  return (
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<TopPage path='/' />} />
        <Route path="/About" element={<About path='/About' />} />
        {/* <Route path="/News" element={<StayTune path='/News' />} /> */}
        {/* <Route path="/Blog" element={<StayTune path='/Blog' />} /> */}
        {/* <Route path="/Link" element={<StayTune path='/Link' />} /> */}
        {/* <Route path="/Contact" element={<Contact />} /> */}
        <Route path="/StayTune" element={<StayTune path='/StayTune' />} />
        {/* <Route path="/Sandbox" element={<SandBox />} /> */}
        <Route path="*" element={<NotFound path='not-found' />} />
      </Routes>
      </BrowserRouter>
  );
}

export default App;
