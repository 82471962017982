import React, { useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import BackgroundImage from '../../components/background-image/BackgroundImage';
import Copyright from '../../components/copyright/Copyright';
import NowLoading from '../../components/loading/Loading';

import './TopPage.css';

const TopPage: React.FC<{path:string}> = ({path}) => {
  const isDark = true;

  const childStyle: React.CSSProperties = {
    position: 'absolute',
    top: '20%',
    left: '5%',
    transform: 'translate(0%, 0%)',
    zIndex: 99,
    fontWeight: "100",
  };

  const fadeIn = useSpring({
    opacity: 1,
    display: 'flex',
    color: 'white',
    delay: 1000,
    from: {
      opacity: 0,
    },
    config: {
      duration: 500
    },
  });

  const topPageStyles: { [key: string]: React.CSSProperties } = {
    h1: {
      fontSize: '4em',
      margin: 0,
      fontWeight: 300,
      color: 'inherit',
      opacity: 'inherit',
      backgroundColor:'black'
    },
    h2: {
      fontSize: '2em',
      margin: 0,
      fontWeight: 50,
      color: 'inherit',
      opacity: 'inherit',
      textAlign: 'right'
    },
    nav:{
      marginTop:'30%'
    },
    footer:{
      position: 'fixed',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      bottom: 10,
      right: 10,
      zIndex: 99,
      fontWeight: "100",
      color: "white"
    }
  };

  useEffect(() => {
    // Google Analytics page view tracking
    window.gtag('config', 'G-MKC0VBTJ3D', {
      'page_path': window.location.pathname
    });
  }, []);

  return (
    <React.Fragment>
      <div style={{ display: 'flex'}}>
        {/* <NowLoading /> */}
        <div style={childStyle}>
          {/* <animated.h1 style={{ ...topPageStyles.h1, ...fadeIn }}>Yuki Yamda</animated.h1> */}
          {/* <animated.h2 style={{ ...topPageStyles.h2, ...fadeIn }}>@shakke.jp</animated.h2> */}
          <animated.h1 style={{ ...topPageStyles.h1, ...fadeIn }}>Shakke.jp</animated.h1>
          <animated.nav className='page-link-nav' style={{ ...topPageStyles.nav, ...fadeIn }}>
            <ul className='page-link'>
              <li className='page-link-li'><a className='page-link-a' href="/About">About</a></li>
              {/* <li className='page-link-li'><a className='page-link-a' href="/News">News</a></li>
              <li className='page-link-li'><a className='page-link-a' href="/Blog">Blog</a></li>
              <li className='page-link-li'><a className='page-link-a' href="/Link">Link</a></li> */}
              {/* <li className='page-link-li'><a className='page-link-a' href="/Contact">Contact</a></li> */}
            </ul>
          </animated.nav>
        </div>
      </div>
      <BackgroundImage isDark={isDark} />
      <animated.div style={{...topPageStyles.footer, ...fadeIn}}>
        <Copyright />
      </animated.div>
    </React.Fragment>
  );
};

export default TopPage;