// TemplateComponent.tsx
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

interface ThreeColumnTemplateProps {
    left?: React.ReactNode;
    center: React.ReactNode;
    right?: React.ReactNode;
}

const ThreeColumn: React.FC<ThreeColumnTemplateProps> = ({ left, center, right }) => {
    return (
        <Container fluid>
            <Row noGutters>
                <Col md={2} className="column">
                    {left}
                </Col>
                <Col md={8} className="column">
                    {center}
                </Col>
                <Col md={2} className="column">
                    {right}
                </Col>
            </Row>
        </Container>
    );
};

export default ThreeColumn;
