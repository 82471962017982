import React, { useRef, useState, useEffect } from 'react';
import HeaderNavbar from '../../components/header-navbar/HeaderNavbar';
import YoutubeLink from '../../components/link-icons/youtube/YoutubeLink';
import TopImage from '../../components/top-image/TopImage';
import ThreeColumn from '../../templates/three-column/ThreeColumn';

import './About.css';

const About: React.FC<{path:string}> = ({path}) => {
    const imageSource = 'image/webp/marmot.webp';
    const iconSize = '6em';
    const indent = '2em';

    useEffect(() => {
        // Google Analytics page view tracking
        window.gtag('config', 'G-MKC0VBTJ3D', {
          'page_path': window.location.pathname
        });
      }, []);

    const centerComponents = (
        <React.Fragment>
            <TopImage style={{marginTop:'1em'}}/>
            <h2 style={{
                paddingLeft: '1em',
                // paddingBottom: '10px',
                marginTop: '1.5em',
                borderBottomStyle: 'solid',
                borderBottomColor: 'gray',
                borderBottomWidth: '1px'
            }}>About</h2>
            <div style={{
                marginTop: '2em',
                marginLeft: indent
            }}>
                <img
                    src={imageSource}
                    width="100%"
                    style={{
                        display: 'inline-block',
                        width: iconSize,
                        height: iconSize,
                        border: 'solid',
                        borderWidth: '2px',
                        borderRadius: '50%',
                        verticalAlign:'top'
                    }}
                ></img>
                <div style={{
                    display: 'inline-block',
                    marginLeft:'1em',
                    verticalAlign:'top'
                }}>
                    <dl style={{ margin: '0', }}>
                        <dt>山田佑樹</dt>
                        <dd>Yuki Yamada</dd>
                    </dl>
                    <YoutubeLink />
                </div>
            </div>
            <div style={{ marginTop: '2em', paddingLeft: indent, marginBottom:'5em'}}>
                <p className='about-text'>
                    初めまして
                    <br />
                    山田と申します。
                    <br />
                    <br />
                    現在、平凡なサラリーマンとして過ごしています。
                    <br />
                    好きなものは、PC、ガジェットとカメラ、美味しいご飯(お酒)です。
                    <br />
                    <br />
                    ここはそんな山田の個人サイトです。
                </p>
            </div>
        </React.Fragment>);

    return (
        <React.Fragment>
            <HeaderNavbar />
            <ThreeColumn
                left={<React.Fragment></React.Fragment>}
                center={centerComponents}
                right={<React.Fragment></React.Fragment>}
            ></ThreeColumn>
        </React.Fragment>
    );
};

export default About;
