import React, { useRef, useState, useEffect } from 'react';

import './YoutubeLink.css';

const YoutubeLink: React.FC<{style?:React.CSSProperties}> = ({style}) => {
    const channnelUrl = 'https://www.youtube.com/@suima1221';
    const handleClick = () => {
        window.open(channnelUrl, '_blank');
    };

    return (
        <img
        src='/image/svg/icon_youtube_gray.svg'
        alt="Youtube"
        className="youtube-icon"
        style={style}
        onClick={handleClick}
    />
    );
};

export default YoutubeLink;
