import React, { useEffect } from 'react';
import { useSpring, animated } from "react-spring";

import './NotFound.css'

const NotFound: React.FC<{ path: string }> = ({ path }) => {
    const fadeIn = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 300 },
    });

    const slideIn = useSpring({
        from: { opacity: 0, transform: "translateY(50%)" },
        to: { opacity: 1, transform: "translateY(0%)" },
        config: { duration: 300 },
    });

    useEffect(() => {
        // Google Analytics page view tracking
        window.gtag('config', 'G-MKC0VBTJ3D', {
          'page_path': window.location.pathname
        });
      }, []);
    
    return (
        <div
            className='not-found'
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                flexDirection: "column",
            }}
        >
            <animated.p style={fadeIn} className='not-found-text'>
                <span className='font-large'>Sorry.</span>
                <br />404 Not Found
            </animated.p>

            <animated.a
                href="/"
                style={{
                    ...fadeIn,
                    fontSize: "1.5em",
                    fontWeight: "100",
                    color: "white",
                    textDecoration: "none",
                    marginTop: "2em",
                }}
            >
                Back To Top
            </animated.a>
        </div>
    )
}
export default NotFound;